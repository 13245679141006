<template>

  <div>

    <div v-if="isLoading">
      <div class="alert alert-info">Loading...</div>
    </div>

    <div v-else>

      <div v-if="user">

        <AdminNavbar :user="user"></AdminNavbar>
        <router-view class="mt-5"></router-view>
      </div>
      <div v-else>
        <LoginView/>
      </div>

    </div>

  </div>


</template>

<script>
import LoginView from "@/views/Admin/LoginView";
import {getMe} from "@/repository/AdminDataRepository";
import AdminNavbar from "@/views/Admin/AdminNavbar";
export default {
  name: "AdminPage",
  components: {AdminNavbar, LoginView},
  data() {

    return {
      user: null,
      isLoading: true
    }
  },
  mounted() {
    let token = localStorage.getItem('token');

    if(token){

      getMe(token).then(data => {
        this.isLoading = false;
        if (data.length < 1) {
          alert("Something went wrong!");
          location.reload();
        } else {
          if (data.code === 401) {
            localStorage.removeItem("token");
            this.user = null;
          } else {
            this.user = data.data;
          }
        }
      });

    } else {
      this.isLoading = false;
    }

  }
}
</script>

<style scoped>

</style>