import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import ArticleList from "@/views/Admin/ArticleList";
import AdminPage from "@/views/Admin/AdminPage";
import AdminHome from "@/views/Admin/AdminHome";
import ArticlePage from "@/views/Admin/ArticlePage";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/admin",
    name: "admin",
    component: AdminPage,
    children:[

      {
        path:'',
        name: 'adminHome',
        component: AdminHome
      },
      {
        path:'articles',
        name: 'adminArticles',
        component: ArticleList
      },
      {
        path: 'article/:id',
        name: 'adminArticle',
        component: ArticlePage
      },
    ]


  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
