<template>

  <div class="container">
    <div class="list-group" v-if="article===null">
      <div class="alert alert-info">Loading content...</div>
    </div>
    <div v-else>

      <div class="card bg-secondary my-4" v-if="editing">

        <div class="card-body">

          <form @submit.prevent="saveArticle">
            <div class="row">
              <div class="col-md-8">

                <div class="form-floating mb-3">
                  <input type="text" readonly class="form-control" id="articleTitle"
                         v-model="article.language.name">
                  <label for="articleTitle">Language</label>
                </div>

                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="articleTitle" v-model="article.title">
                  <label for="articleTitle">Title</label>
                </div>

                <div class="row">
                  <div class="col-md-6">

                    <div class="form-floating mb-3">
                      <input type="text" class="form-control" id="articleListOrder" v-model="article.listOrder">
                      <label for="articleListOrder">List order</label>
                    </div>

                  </div>
                  <div class="col-md-6">
                    <div class="form-floating mb-3" :class="{' border border-danger text-danger': article.status<1}">
                      <select class="form-control form-control-sm" v-model="article.status">
                        <option value="1">active</option>
                        <option value="0">inactive</option>
                      </select>
                      <label>Status</label>
                    </div>
                  </div>
                </div>

              </div>
              <div class="col-md-4">


                <FileUpload :file-path="article.photo" @changed="article.photo = $event"/>

              </div>
            </div>

            <div>
              <button type="submit" class="btn btn-sm btn-primary me-2">save</button>

              <button type="button" class="btn btn-sm btn-danger" @click.prevent="editing = false">cancel</button>
            </div>
          </form>
        </div>
      </div>

      <div class="card mb-3" v-else>
        <div class="card-body">
          <div class="row">
            <div class="col-2">
              <img v-if="article.photo" :src="`${mediaBase}/${article.photo}`" class="img-fluid">
            </div>
            <div class="col-10">
              <span class="badge bg-primary">{{article.language.name}}</span>
              <p class="card-title">
                {{ article.title }}

                <span class="badge bg-success" v-if="article.status>0">active</span>
                <span class="badge bg-danger" v-else>inactive</span>

              </p>
              <button type="button" class="btn btn-sm btn-primary" @click.prevent="editing = true">edit</button>
            </div>
          </div>
        </div>
      </div>


      <div class="bg-light rounded-4 border border-dark p-3 my-3"
           @click.prevent="addRow(1)"
           style="border-style: dashed !important; cursor: pointer">
        + add new row to top
      </div>

      <div v-for="(row,j) in rows" :key="row.id">
        <ContentRow :row-id="row.id" :key="row.id" @deleted="rows.splice(j,1)" @add-row-after="addRow(row.listOrder+1)" />
      </div>
      <div class="bg-light rounded-4 border border-dark p-3 my-3"
           @click.prevent="addRow(rows.length+1)" v-if="rows.length>0"
           style="border-style: dashed !important; cursor: pointer">
        + add new row to end
      </div>

    </div>
  </div>
</template>

<script>


import {createRow, getArticle, saveArticle} from "@/repository/AdminDataRepository";
import {customAlert} from "@/utils/utils";
import ContentRow from "@/views/Admin/ContentRow";
import FileUpload from "@/components/FileUpload";
import {mediaBase} from "@/repository/repository";

export default {
  name: "ArticlePage",
  components: {FileUpload, ContentRow},
  data() {
    return {
      mediaBase: mediaBase,
      article: null,
      rows: [],
      editing: false
    }
  },

  methods: {
    deleteBlock(path) {

      let parts = path.split('_');
      this.articles[this.editingArticleIndex].content[parseInt(parts[0])].cols.splice(parseInt(parts[1]), 1)

    }
    , loadArticle() {
      getArticle(this.$route.params.id).then(d => {

        if (d.code === 200) {
          this.article = d.item;
          this.rows = d.rows;
        } else {
          customAlert('Error ' + d.code, d.message, 'error');
        }

      });
    },
    saveArticle() {
      saveArticle(this.article.id, this.article.title, this.article.photo, this.article.listOrder, this.article.status).then(res => {

        if (res.code === 200) {
          customAlert("success", "Saved", 'success');
          this.loadArticle();
          this.editing = false;
        } else {
          customAlert(`Error ${res.code}`, res.message, 'error');
        }

      })
    },
    addRow(listOrder) {
      createRow(this.article.id, listOrder).then(res => {

        if (res.code === 200) {
          this.loadArticle();
        } else {
          customAlert(`Error ${res.code}`, res.message, 'error');
        }

      });
    }
  },
  computed: {},
  mounted() {
    this.loadArticle();
  }
}
</script>
