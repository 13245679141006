<template>

  <div class="container">
    <h1>Welcome</h1>
  </div>
</template>

<script>


export default {
  name: "AdminHome",

}
</script>
