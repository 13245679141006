<template>
  <video-background
      src="/img/bg.mp4"
      poster="/img/background.jpeg"
      style="min-height: 100vh; height: auto"
      overlay="linear-gradient(45deg,#cc4ae430,#cc949e6b)"
  >


    <div class="hero">
      <div class="menu d-flex justify-content-between align-items-center">
        <a class="btn btn-light" href="#" @click.prevent="articleIndexIsOpen = !articleIndexIsOpen">
          <font-awesome-icon icon="fa-solid fa-fw fa-times" v-if="articleIndexIsOpen"/>
          <font-awesome-icon icon="fa-solid fa-fw fa-bars" v-else/>
        </a>

        <div class="language-list">
          <a href="#" class="language-item mx-2" :class="{'active': l.code === languageCode }"
             @click="selectLanguage(l)" v-for="l in languages" :key="l.id">
            {{ l.name }}
          </a>
        </div>

      </div>

      <div class="content">

        <div class="title">{{ $t('homepage.slogan') }}</div>

        <KhariBulbul :articles="articles" class="flower"
                     @mouse_in="popup=$event"
                     @mouse_out="popup=null"
                     @clicked="loadArticle($event)"
        />

        <div v-if="popupArticle!= null"
             :style="{ 'z-index': 220, width:  popupWidth+'px', position: 'absolute', left: `${popup.coordinates.x}px`, top: `${popup.coordinates.y+10}px`}">
          <div class="card shadow" style="overflow: hidden">
            <ArticleBanner :article="popupArticle" @click.prevent.stop="article=popupArticle.id"/>
          </div>
        </div>


        <div class="my-5" style="z-index: 100;" v-if="!articleIndexIsOpen">
          <div class="py-3 px-5 d-inline-block bg-dark text-white rounded-2 text-center"
               style="z-index: 100; line-height: 1.5"
          >
            {{ $t('homepage.clickParts') }}
            <button class="btn btn-sm btn-outline-light ms-2" @click.prevent="loadArticle(articles[0].id)">
              {{ $t('homepage.startReading') }}
            </button>
          </div>
        </div>

        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-3 col-md-4 col-sm-6 mb-3">
              <img src="../assets/logo/qht.png" class="w-100 rounded-3"
                   @click.prevent="loadArticle(articles.pop().id)"
                   style="cursor: pointer" alt="logo">
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 mb-3">
              <img src="../assets/logo/adpr.png" class="w-100 rounded-3"
                   @click.prevent="loadArticle(articles.pop().id)"
                   style="cursor: pointer" alt="logo">
            </div>
          </div>
        </div>

      </div>

      <div class="articleList" v-if="articleIndexIsOpen" @click.prevent="articleIndexIsOpen=false">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 my-3" v-if="articles.length<1">
              <div class="alert alert-warning">{{ $t('articles.noArticleFound') }}</div>
            </div>
            <div class="col-lg-6 mb-3" v-for="a in articles" :key="a.id">
              <ArticleBanner @click.prevent.stop="loadArticle(a.id)" :article="a" class="shadow-4"/>
            </div>
          </div>
        </div>

      </div>


    </div>


  </video-background>

  <div class="articleModal" v-if="article!==null" @click.prevent="article=null">
    <div class="content lead position-relative" @click.stop id="articleContent">

      <div class="fixed-top bg-white">
        <div class="d-flex justify-content-between p-2">
          <div class="d-flex align-items-bottom" v-if="isPlaying">
          <a class="btn btn-sm btn-dark" @click.prevent="pauseAudio">
            <font-awesome-icon icon="fa-solid fa-fw fa-pause" />
          </a>
          </div>
          <a class="btn btn-sm btn-dark" v-else @click.prevent="playAudio">
            <font-awesome-icon icon="fa-solid fa-fw fa-play"/>
          </a>


          <div class="now  ms-2" :class="{playing: isPlaying}" id="music">
            <span class="bar n1"></span>
            <span class="bar n2"></span>
            <span class="bar n3"></span>
            <span class="bar n4"></span>
            <span class="bar n5"></span>
            <span class="bar n6"></span>
            <span class="bar n7"></span>
            <span class="bar n8"></span>
          </div>


          <a class="btn btn-sm btn-outline-danger" @click="article=null">
            <font-awesome-icon icon="fa-solid fa-fw fa-times"/>
          </a>

        </div>
      </div>


      <div class="py-3">

        <div v-if="!article.rows || article.rows.length <1">

          <h1 class="text-center my-5">{{ article.title }}</h1>

          <div class="alert alert-secondary text-center">
            <h3>{{ $t('comingSoon.title') }}</h3>
            <p>{{ $t('comingSoon.description') }}</p>
          </div>
        </div>

        <template v-for="row in article.rows" :key="row.id">
          <ContentBlockRow :row="row"/>
        </template>

        <hr>
        <div class="container">
          <div class="row  mt-5">
            <div class="col-md-6 mb-3">
              <p v-if="prevArticle"
                 @click.prevent.stop="loadArticle(prevArticle.id)">{{ $t('general.previousArticle') }}</p>
              <ArticleBanner :article="prevArticle" v-if="prevArticle"
                             @click.prevent.stop="loadArticle(prevArticle.id)"/>
              <div class=" h-100 d-flex align-items-center justify-content-center" v-else>
                <a class="btn btn-lg btn-outline-dark"
                   @click.prevent.stop="article=null; articleIndexIsOpen = true;  ">{{
                    $t('general.allArticles')
                  }}</a>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <p v-if="nextArticle"
                 @click.prevent.stop="loadArticle(nextArticle.id)"
                 class="text-end">{{ $t('general.nextArticle') }}</p>
              <ArticleBanner :article="nextArticle" v-if="nextArticle"
                             @click.prevent.stop="loadArticle(nextArticle.id)"/>
              <div class=" h-100 d-flex align-items-center justify-content-center" v-else>
                <a class="btn btn-lg btn-outline-dark"
                   @click.prevent.stop="article=null; articleIndexIsOpen = true;  ">
                  {{ $t('general.allArticles') }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="modal fade show" style="z-index: 10000;" :style="{'display': languagePopupIsOpen?'block':'none'}"
       id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
       aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">

          <div class="list-group list-group-flush">
            <a href="#" class="list-group-item" :class="{'active': l.code === languageCode }"
               @click="selectLanguage(l)" v-for="l in languages" :key="l.id">
              {{ l.name }}
            </a>
          </div>

        </div>
      </div>
    </div>
  </div>

</template>

<script>
// @ is an alias to /src

import KhariBulbul from "@/views/KhariBulbul";
import ArticleBanner from "@/views/ArticleBanner";
import {getArticle, getArticleList, getLanguageList} from "@/repository/PublicDataRepository";
import {customAlert} from "@/utils/utils";
import ContentBlockRow from "@/views/ContentBlockRow";

export default {
  name: "HomeView",
  components: {ContentBlockRow, ArticleBanner, KhariBulbul},
  data() {

    return {
      languageCode: '',
      languages: [],
      languagePopupIsOpen: false,

      articleIndexIsOpen: false,
      popup: null,
      article: null,
      articles: [],
      popupWidth: 0,
      isPlaying: false,
      backgroundAudio: null
    }

  },

  watch: {
    languageCode(newVal) {
      getArticleList(newVal).then(d => {
        this.articles = d;
      })
    },
    article(newVal) {
      if (newVal !== null) {
        history.pushState({}, null, this.$route.path + '?article=' + newVal.id);
        document.title = newVal.title;
      } else {
        history.pushState({}, null, this.$route.path);
        let backgroundAudio = document.getElementById("backgroundAudio");
        if (backgroundAudio) {
          backgroundAudio.pause();
        }
      }
      let element = document.getElementById('articleContent');
      if (element) {
        element.scrollTo(0, 0);
      }
    },
    popup: {
      deep: true,
      handler(newVal) {
        if (newVal != null) {
          if (newVal.coordinates.x + this.popupWidth > window.innerWidth) {
            newVal.coordinates.x = window.innerWidth - this.popupWidth - 30;
          }
        }
      }
    },
  },

  methods: {
    selectLanguage(language) {
      this.$i18n.locale = language.code;
      this.languageCode = language.code;
      this.languagePopupIsOpen = false;
      localStorage.setItem("languageCode", language.code)
    },

    loadArticle(id) {
      getArticle(id).then(({code, message, item}) => {

        if (code === 200) {
          this.article = item; 
        } else {
          customAlert('Error' + code, message, 'error');
        }

      });
    },
    pauseAudio(){
      this.backgroundAudio.pause();
    },
    playAudio(){
      this.backgroundAudio.play();
    }
  },

  mounted() {
    if (this.$route.query['article']) {
      this.loadArticle(parseInt(this.$route.query['article']));
    }
    if (window.innerWidth - 60 > 400) {
      this.popupWidth = 400;
    } else {
      this.popupWidth = window.innerWidth - 60;
    }

    this.backgroundAudio = document.getElementById("backgroundAudio");

    this.backgroundAudio.addEventListener('play', () => {
      this.isPlaying = true;
    });

    this.backgroundAudio.addEventListener('pause', () => {
      this.isPlaying = false;
    });

    getLanguageList().then(d => {
      this.languages = d;
    });

    let languageCode = localStorage.getItem("languageCode")

    if (languageCode) {
      this.languageCode = languageCode;
      this.$i18n.locale = languageCode;
      getArticleList(languageCode).then(d => {
        this.articles = d;
      })
    } else {
      this.languageCode = 'ru';
      localStorage.setItem("languageCode", 'ru');
      this.$i18n.locale = 'ru';
    }
  },

  computed: {
    popupArticle() {

      if (this.popup != null) {

        let filtered = this.articles.filter(a => a.id === this.popup.id);
        if (filtered.length < 1) {
          return null;
        } else {
          return filtered[0];
        }
      }
      return null;
    },
    nextArticle() {

      if (this.article != null) {

        let filtered = this.articles.filter(a => a.listOrder > this.article.listOrder);
        if (filtered.length < 1) {
          return null;
        } else {
          return filtered[0];
        }
      }
      return null;
    },
    prevArticle() {

      if (this.article != null) {

        let filtered = this.articles.filter(a => a.listOrder < this.article.listOrder);
        if (filtered.length < 1) {
          return null;
        } else {
          return filtered[filtered.length - 1];
        }
      }
      return null;
    }
  }
};
</script>
<style lang="scss" scoped>

* {

}

.hero {
  padding-top: 100px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background-size: cover;
  overflow: auto;

  &:before {
    content: '';
    display: block;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .content {
    z-index: 100;
    text-align: center;

    .title {
      color: white;
      font-size: 60px;
      text-align: center;
      font-weight: 400;
      padding: 10px 40px;

      @media screen and (max-width: 700px) {
        font-size: 40px;
      }
    }

    .flower {
      width: 350px;
      max-width: 70vw;
      height: 350px;
      max-height: 70vh;
      z-index: 100;
    }
  }

  .menu {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    padding: 20px;
    background: rgba(0, 0, 0, 0.7);
    height: 80px;


    .language-list {
      z-index: 100;

      .language-item {
        color: white;
        font-weight: normal;
        text-decoration: none;
        padding: 5px 10px;

        &.active {
          font-weight: bold;
          background: white;
          color: black;
        }
      }
    }
  }

}

.articleList {
  position: absolute;
  right: 0;
  top: 80px;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 101;
  overflow: auto;

}

.articleModal {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 200;

  .content {
    margin: 0;
    background: white;
    overflow: auto;

    height: 100vh;
  }

}

::v-deep {
  .videobg-content {
    overflow: auto !important;
  }
}

</style>