<template>
  <div v-html="blockData.text" v-if="col.type==='text'"></div>
  <div v-if="col.type==='header'">
    <div class="py-5">
      <h1 class="text-center" v-html="blockData.text.replace('\n','<br>')"></h1>
    </div>

  </div>
  <div v-if="col.type==='youtube'" :class="`ratio ratio-${blockData.ratio}`">
    <div v-if="blockData.poster && !videoPlay" style="overflow: hidden; cursor: pointer"
         @click.prevent="videoPlay = true">
      <img :src="`${mediaBase}/${blockData.poster}`" :alt="blockData.alt"  class="w-100 ">
      <div style="position: absolute; left: 0; right: 0; top: 0; bottom: 0;"
           class="d-flex justify-content-center align-items-center ">
        <font-awesome-icon icon="fa-solid fa-circle-play" size="10x" class="text-white"/>
      </div>
    </div>
    <iframe v-else :src="youtubePath"
            title="YouTube video player" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
  </div>
  <div v-if="col.type==='iframe'" :class="`ratio ratio-${blockData.ratio}`">
    <iframe :src="blockData.url"
            title="YouTube video player" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
  </div>
  <div v-if="col.type==='code'" v-html="blockData.code"></div>
  <div v-if="col.type==='photo'">
    <figure class="figure w-100">
      <div :class="`ratio ratio-${blockData.ratio} w-100`">
        <div style="overflow: hidden" class="rounded">
          <inner-image-zoom :src="`${mediaBase}/${blockData.path}`" :alt="blockData.alt"
                            v-if="blockData.zoom==='yes'"
                            :fullscreenOnMobile="true"
                            class="figure-img w-100 "/>
          <img :src="`${mediaBase}/${blockData.path}`" :alt="blockData.alt"
               v-else
               class="figure-img w-100 ">

        </div>
      </div>
      <figcaption class="figure-caption">
        {{ blockData.alt }}
      </figcaption>
    </figure>
  </div>
  <div v-if="col.type==='autoplay_audio' && isAdmin">
    <audio controls loop="loop">
      <source :src="`${mediaBase}/${blockData.path}`" type="audio/mpeg">
      Your browser does not support the audio element.
    </audio>
  </div>

</template>
<script>
import {mediaBase} from "@/repository/repository";

import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css'
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  name: 'ContentBlockItem',
  components: {FontAwesomeIcon},
  props: {
    col: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    isAdmin: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  computed: {
    youtubePath() {

      if (this.col !== null && this.col.type === 'youtube' && this.blockData.video) {


        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        var match = this.blockData.video.match(regExp);
        var videoId = (match && match[7].length === 11) ? match[7] : '';

        let second = this.blockData.start;
        if (!second) {
          second = 0;
        }

        let autoplay = this.blockData.poster?1:0;

        return 'https://www.youtube-nocookie.com/embed/' + videoId + "?autoplay="+autoplay+"&start=" + second;

      }

      return '';

    }
  },
  data() {
    return {

      mediaBase: mediaBase,
      blockData: {},
      videoPlay: false
    }
  }, created() {
    this.blockData = JSON.parse(this.col.contentText);

    if (this.col.type === 'autoplay_audio' && !this.isAdmin) {

      let backgroundAudio = document.getElementById("backgroundAudio");
      let backgroundAudioSource = document.getElementById('backgroundAudioSource');
      if (backgroundAudio && backgroundAudioSource) {
        backgroundAudioSource.src = `${mediaBase}/${this.blockData.path}`;
        backgroundAudio.load();
        backgroundAudio.play();
      }
    }
  }
}
</script>
<style lang="scss" scoped>

::v-deep {
  * {
    font-family: 'Playfair Display SC', serif;
  }


  .ratio-free, .ratio-undefined {
    height: auto;

    > * {
      position: relative !important;
    }
  }

  .ratio-1x2 {
    --bs-aspect-ratio: 200%;
  }

  .ratio-3x4 {
    --bs-aspect-ratio: 132%;
  }

  .ql-align-justify {
    text-align: justify;
  }

  .ql-align-left {
    text-align: left;
  }

  .ql-align-center {
    text-align: center;
  }

  .ql-align-right {
    text-align: right;
  }

  blockquote {
    border-left: 5px solid #888888;
    padding: 10px 20px;
  }
}
</style>
