<template>
  <svg
      class="svg-element"
      :class="{active: isDrawing, ready: isReady}"
      width="806px"
      height="792px"
      viewBox="0 0 81 80"
      version="1.1"
      id="svg5"
      xml:space="preserve"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:svg="http://www.w3.org/2000/svg">
    <defs>
      <pattern id="img1" patternUnits="userSpaceOnUse" width="620" height="413">
        <image href="https://thumb.tildacdn.com/tild3364-6263-4435-b137-646362363165/-/format/webp/__.jpeg" x="0" y="0" width="620" height="413"/>
      </pattern>
    </defs>
    <g
        id="layer1"
        transform="translate(-109.46936,-108.00311)">
      <path fill="#e7c933"  stroke="#ffffff" stroke-width="0.5" class="svg-elem-1"
          d="m 164.46693,143.9702 c 0,0 0.24076,-4.70061 -1.27364,-6.56715 -1.51436,-1.86654 -2.09148,-2.61662 -0.0919,-4.07142 0.89433,-0.65069 0.79739,-1.09601 3.93177,-1.06079 3.13438,0.0352 6.20302,-0.0634 2.46994,-2.56384 -3.73308,-2.50046 -4.72086,-4.41541 -6.79871,-4.87324 -2.07784,-0.45783 -5.35141,-0.44464 -6.68969,1.24581 -1.33828,1.69046 -4.58572,7.21534 -4.93049,7.81835 -1.20683,2.11079 -2.60611,5.45875 -6.51528,-0.31696 -3.90917,-5.77572 -19.29933,-22.50415 -19.29933,-22.50415 0,0 -7.99444,-8.41704 -7.81835,2.60611 0.17609,11.02316 10.43573,23.31557 14.66609,26.61618 4.23035,3.30061 14.74334,10.71482 9.59605,7.45502 -5.17253,-3.27578 -4.7582,-4.05681 -8.8026,-1.54649 -4.04441,2.51032 -14.21421,15.07472 -18.39402,21.74055 -4.81985,7.68655 -8.84616,20.92827 1.05567,18.46443 9.90182,-2.46383 20.51745,-6.18783 23.95753,-11.4874 3.44007,-5.29957 0.30428,-3.01466 5.55736,-1.29462 3.81559,1.24935 4.9333,-1.79338 5.97187,-0.66623 0.39128,0.42464 0.77132,1.44113 1.28017,3.50197 1.85949,7.53096 11.43398,13.96183 10.04129,6.50824 -0.81752,-4.3753 2.46283,1.47037 10.5334,3.11933 6.63895,1.35645 9.27025,1.5759 10.89731,0.13479 1.62706,-1.44111 2.12038,-2.13122 2.13842,-3.39358 0.018,-1.26236 -0.66883,-2.08306 -1.813,-3.06817 -1.68402,-1.4499 0.0591,-5.25309 -0.23244,-8.60018 -0.30042,-3.44862 -1.25516,-6.36878 -4.13738,-8.60018 -2.88223,-2.23139 -5.43903,-1.30165 0.65082,-6.6477 6.08986,-5.34606 16.96792,-16.54953 2.51032,-11.80781 -14.45759,4.74172 -19.67546,12.82771 -18.46115,-0.14087 z"
          id="background"/>
      <path fill="#9526a8"  stroke="#ffffff" stroke-width="0.5" class="svg-elem-2"
          d="m 154.19504,169.9428 c -0.95975,0.77126 -1.75678,1.18941 -1.65383,2.43657 0.224,2.71348 3.19646,8.72072 5.46981,10.95484 1.26321,1.24141 2.47684,1.60463 2.96561,0.4645 -1.00122,-5.43559 -3.66799,-8.53457 -6.78159,-13.85591 z"
          id="tail" @click.prevent="clickHandled('tail')" @mouseover="mouseHandler($event,'tail','in')"
          @mouseout="mouseHandler($event,'tail','out')"/>
      <path fill="#9526a8"  stroke="#ffffff" stroke-width="0.5"  class="svg-elem-3"
          d="m 142.93085,156.77377 c -1.57208,1.26867 -4.94696,1.72644 -3.46428,6.54834 3.46007,9.15575 6.58931,11.85879 13.15962,5.29555 2.11705,-2.11477 0.90469,-7.9899 4.0356,-14.48456 0.84289,-1.74846 0.905,-4.54073 0.90222,-6.66041 -1.79664,2.01465 -2.18172,2.21446 -3.14445,2.81959 -0.66871,0.42032 -1.93721,0.62763 -3.2803,1.72192 -3.2672,2.66198 -6.47808,3.3632 -8.20841,4.75957 z"
          id="body" @click.prevent="clickHandled('body')" @mouseover="mouseHandler($event,'body','in')"
          @mouseout="mouseHandler($event,'body','out')"/>
      <path fill="#9526a8"  stroke="#ffffff" stroke-width="0.5"  class="svg-elem-4"
          d="m 156.60899,159.22005 c -0.80391,2.68648 -0.91437,5.28242 -1.20428,8.84138 3.08826,7.16968 10.6693,15.04651 17.84057,16.62501 2.50825,0.55245 7.19152,1.84944 9.95777,-0.22307 0.92917,-1.29118 1.11955,-1.78407 -0.91342,-3.73514 -1.06658,-1.02361 -0.0418,-4.45337 -0.12919,-7.08126 0.1794,-2.73633 -0.35192,-5.22601 -1.5579,-7.31251 -2.87443,-4.97417 -8.45726,-5.2322 -11.52,-7.82172 -9.6017,-8.11816 -3.82186,-13.003 -10.09916,-11.46557 0.51711,4.5549 -1.18424,8.19564 -2.37439,12.17288 z"
          id="right_tail" @click.prevent="clickHandled('right_tail')"
          @mouseover="mouseHandler($event,'right_tail','in')" @mouseout="mouseHandler($event,'right_tail','out')"/>
      <path fill="#9526a8"  stroke="#ffffff" stroke-width="0.5" class="svg-elem-5"
          d="m 133.0212,178.01894 c 2.79878,-1.70815 5.89165,-4.03233 6.65894,-7.03694 0.91705,-3.58669 -3.61628,-7.5457 -1.98573,-11.82474 0.48801,-2.49006 6.22042,-2.96001 6.27984,-5.38995 0.0651,-2.66408 -1.35913,-2.88543 -3.84581,-5.41425 0,0 -2.11674,-1.46151 -3.17084,-1.693 -2.81762,-0.61877 -5.68991,3.58218 -9.22117,6.96621 -7.92806,8.8971 -19.77272,26.91709 -15.87995,31.23392 6.30572,0.59418 16.29957,-4.01994 21.16472,-6.84125 z"
          id="left_tail" @click.prevent="clickHandled('left_tail')" @mouseover="mouseHandler($event,'left_tail','in')"
          @mouseout="mouseHandler($event,'left_tail','out')"/>
      <path fill="#9526a8"  stroke="#ffffff" stroke-width="0.5"  class="svg-elem-6"
          d="m 167.05697,152.87569 c 0,0 3.89359,5.38578 8.03595,6.07085 2.8274,-2.39315 3.80574,-3.40324 4.82413,-4.28709 4.46754,-4.40835 13.75543,-12.78332 4.72786,-9.60671 l -6.63208,2.92708 c 0,0 -5.6645,2.77142 -7.70556,3.69477 -2.04105,0.92335 -3.12324,1.10593 -3.2503,1.2011 z"
          id="right_wing" @click.prevent="clickHandled('right_wing')"
          @mouseover="mouseHandler($event,'right_wing','in')" @mouseout="mouseHandler($event,'right_wing','out')"/>
      <path fill="#9526a8"  stroke="#ffffff" stroke-width="0.5"  class="svg-elem-7"
          d="m 147.07553,138.60232 c -8.32998,-5.88895 -22.31236,-29.39371 -27.39688,-28.13126 -1.12575,0.52033 -0.35063,4.94555 -0.074,5.91817 0.94569,3.32505 1.74499,4.8408 2.76718,7.33937 5.036,10.53544 10.76099,15.56056 21.45811,22.9981 0.0989,-2.79212 0.40205,-5.0572 3.24559,-8.12438 z"
          id="left_wing" @click.prevent="clickHandled('left_wing')" @mouseover="mouseHandler($event,'left_wing','in')"
          @mouseout="mouseHandler($event,'left_wing','out')"/>
      <path fill="#008000"  stroke="#ffffff" stroke-width="0.5"  class="svg-elem-8"
          d="m 154.54406,131.7173 c -1.0714,1.77193 -1.19187,3.59017 -4.35348,6.38898 -4.94684,4.37918 -4.71633,9.48604 -4.7474,11.13482 -0.0348,1.84745 -1.29475,5.69688 3.10739,2.15791 4.40213,-3.53897 3.88424,-1.29475 6.73268,-4.05687 2.84844,-2.76213 5.30496,-2.31853 6.73198,-1.90749 1.28622,0.37049 0.65749,-5.73987 -0.60352,-7.15574 -0.92567,-1.03936 -2.98564,-4.44542 1.1127,-6.46902 0.63251,-0.31231 1.73575,-1.5584 5.79262,-0.60892 4.05687,0.94948 -2.93476,-2.50318 -5.00635,-4.05688 -1.87845,-1.40884 -5.78321,-0.77684 -6.56005,0.43159 -0.77685,1.20843 -1.13517,2.36968 -2.20657,4.14162 z"
          id="head" @click.prevent="clickHandled('head')" @mouseover="mouseHandler($event,'head','in')"
          @mouseout="mouseHandler($event,'head','out')"/>
    </g>
    </svg>
</template>
<script>
export default {
  name: "KhariBulbul",
  props: {
    articles: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data(){
     return {
       isDrawing: false,
       isReady: false
     }
  },
  methods: {

    getArticleId(position) {
      let id = 0;
      switch (position) {
        case 'head':
          id = 1;
          break;
        case 'left_wing':
          id = 2;
          break;
        case 'right_wing':
          id = 3;
          break;
        case 'body':
          id = 4;
          break;
        case 'left_tail':
          id = 5;
          break;
        case 'right_tail':
          id = 6;
          break;
        case 'tail':
          id = 7;
          break;
      }
      return this.articles[id-1].id;
    },

    clickHandled(position) {
      this.$emit('clicked', this.getArticleId(position));
    },
    mouseHandler(event, position, action) {


      this.$emit('mouse_' + action, {
        id: this.getArticleId(position),
        position: position,
        coordinates: {x: event.clientX, y: event.clientY}
      });
    }
  }, emits: ['clicked', 'mouse_in', 'mouse_out'],
  mounted() {
    setTimeout(()=>{
      setInterval(()=>{
        this.isDrawing =  !this.isDrawing;
      }, 3000);
      this.isDrawing =  !this.isDrawing;
      this.isReady = true;
    }, 1500)

  }
};
</script>
<style lang="scss" scoped>
/*

@keyframes col {
  0%,20% {fill:#e7c933}
  20%,45% {fill: #e0d487
  }
  45%,55% {fill: #ffffff
  }
  55%,80% {fill: #e0d487
  }
  80%,100% {fill:#e7c933}
}

#background {
  fill: #e7c933;
  stroke: #9f7e00;
  stroke-width: 0.5;
  stroke-opacity: 0.5;
  animation: col 5s ease infinite;
}

#head {
  cursor: pointer;
  fill: #008000;
  stroke: #ffffff;
  stroke-width: 0.5;
  stroke-opacity: 0;

  &:hover {
    stroke-opacity: 1;
  }
}

#body {
  cursor: pointer;
  fill: #9526a8;
  stroke: #ffffff;
  stroke-width: 0.5;
  stroke-opacity: 0;

  &:hover {
    stroke-opacity: 1;
  }
}



#left_wing {
  cursor: pointer;
  fill: #9526a8;
  stroke: #ffffff;
  stroke-width: 0.5;
  stroke-opacity: 0;

  &:hover {
    stroke-opacity: 1;
  }
}

#right_wing {
  cursor: pointer;
  fill: #9526a8;
  stroke: #ffffff;
  stroke-width: 0.5;
  stroke-opacity: 0;

  &:hover {
    stroke-opacity: 1;
  }
}

#left_tail {
  cursor: pointer;
  fill: #9526a8;
  stroke: #ffffff;
  stroke-width: 0.5;
  stroke-opacity: 0;

  &:hover {
    stroke-opacity: 1;
  }
}

#right_tail {
  cursor: pointer;
  fill: #9526a8;
  stroke: #ffffff;
  stroke-width: 0.5;
  stroke-opacity: 0;

  &:hover {
    stroke-opacity: 1;
  }
}

#tail {
  cursor: pointer;
  fill: #9526a8;
  stroke: #ffffff;
  stroke-width: 0.5;
  stroke-opacity: 0;

  &:hover {
    stroke-opacity: 1;
  }
}
*/

</style>