import Repository, {baseUrl} from './repository';

export async function loginUser(login, password) {
    const endPoint = `admin/login?email=${login}&password=${password}`;
    return await Repository.post(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return {code: 1001, message: 'Unexpected response'}
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return {code: 1000, message: 'Network error'}
        });
}


export async function getMe(token) {
    const endPoint = `admin/me?token=${token}`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return {code: 1001, message: 'Unexpected response'}
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return {code: 1000, message: 'Network error'}
        });
}


export async function upload(file, onUploadProgress) {
    let formData = new FormData();

    formData.append("photo", file);

    return await Repository.post(`${baseUrl}/admin/upload`, formData, {
        headers: {
            "Content-Type": "multipart/form-data"
        },
        onUploadProgress
    }).then(response => {
        return response;
    }).catch(error => {
        console.log(JSON.stringify(error));
        return {code: 2, message: JSON.stringify(error)};
    });
}




/********************************
 * Articles
 */


export async function getLanguageList() {
    let token = localStorage.getItem("token");
    const endPoint = `admin/languages?token=${token}`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data && response.data.code === 200) {
                return response.data.items;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}


/********************************
 * Articles
 */

export async function getArticleList() {
    let token = localStorage.getItem("token");
    const endPoint = `admin/articles?token=${token}`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data && response.data.code === 200) {
                return response.data.items;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}


export async function createArticle(title, languageId, listOrder) {
    let token = localStorage.getItem("token");
    const formData = new FormData()
    formData.append('title', title);
    formData.append('language_id', languageId);
    formData.append('list_order', listOrder);

    const endPoint = `admin/article?token=${token}`;
    return await Repository.post(`${baseUrl}/${endPoint}`, formData)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return {code: 1001, message: 'Unexpected response'}
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return {code: 1000, message: 'Network error'}
        });
}


export async function getArticle(id) {
    let token = localStorage.getItem("token");
    const endPoint = `admin/article/${id}?token=${token}`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return {code: 1001, message: 'Unexpected response'}
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return {code: 1000, message: 'Network error'}
        });
}



export async function saveArticle(id, title, photo, listOrder, status) {
    let token = localStorage.getItem("token");
    const formData = new FormData()
    formData.append('title', title);
    formData.append('photo', photo);
    formData.append('list_order', listOrder);
    formData.append('status', status);

    const endPoint = `admin/article/${id}?token=${token}`;
    return await Repository.post(`${baseUrl}/${endPoint}`, formData)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return {code: 1001, message: 'Unexpected response'}
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return {code: 1000, message: 'Network error'}
        });
}



export async function createRow(articleId, listOrder) {
    let token = localStorage.getItem("token");
    const formData = new FormData()
    formData.append('article_id', articleId);
    formData.append('list_order', listOrder);

    const endPoint = `admin/row?token=${token}`;
    return await Repository.post(`${baseUrl}/${endPoint}`, formData)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return {code: 1001, message: 'Unexpected response'}
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return {code: 1000, message: 'Network error'}
        });
}


export async function getRow(id) {
    let token = localStorage.getItem("token");
    const endPoint = `admin/row/${id}?token=${token}`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return {code: 1001, message: 'Unexpected response'}
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return {code: 1000, message: 'Network error'}
        });
}



export async function saveRow(id, width, align, listOrder, status, config) {
    let token = localStorage.getItem("token");
    const formData = new FormData()
    formData.append('width', width);
    formData.append('align', align);
    formData.append('list_order', listOrder);
    formData.append('status', status);
    formData.append('config', config);

    const endPoint = `admin/row/${id}?token=${token}`;
    return await Repository.post(`${baseUrl}/${endPoint}`, formData)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return {code: 1001, message: 'Unexpected response'}
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return {code: 1000, message: 'Network error'}
        });
}


export async function deleteRow(id) {
    let token = localStorage.getItem("token");
    const endPoint = `admin/row/${id}/delete?token=${token}`;
    return await Repository.post(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return {code: 1001, message: 'Unexpected response'}
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return {code: 1000, message: 'Network error'}
        });
}



export async function saveCell(rowId, cellId, width, listOrder, status, type, text) {
    let token = localStorage.getItem("token");
    const formData = new FormData()
    formData.append('row_id', rowId);
    formData.append('width', width);
    formData.append('list_order', listOrder);
    formData.append('status', status);
    formData.append('type', type);
    formData.append('text', text);

    const endPoint = `admin/cell/${cellId}?token=${token}`;
    return await Repository.post(`${baseUrl}/${endPoint}`, formData)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return {code: 1001, message: 'Unexpected response'}
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return {code: 1000, message: 'Network error'}
        });
}




export async function updateCellOrder( cellId, listOrder) {
    let token = localStorage.getItem("token");
    const formData = new FormData();
    formData.append('list_order', listOrder);

    const endPoint = `admin/cell/${cellId}/order?token=${token}`;
    return await Repository.post(`${baseUrl}/${endPoint}`, formData)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return {code: 1001, message: 'Unexpected response'}
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return {code: 1000, message: 'Network error'}
        });
}





export async function getCell(id) {
    let token = localStorage.getItem("token");
    const endPoint = `admin/cell/${id}?token=${token}`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return {code: 1001, message: 'Unexpected response'}
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return {code: 1000, message: 'Network error'}
        });
}



export async function deleteCell(id) {
    let token = localStorage.getItem("token");
    const endPoint = `admin/cell/${id}/delete?token=${token}`;
    return await Repository.post(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return {code: 1001, message: 'Unexpected response'}
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return {code: 1000, message: 'Network error'}
        });
}


export async function getSubject(id) {
    let token = localStorage.getItem("token");
    const endPoint = `expert/subject/${id}?token=${token}`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return {code: 1001, message: 'Unexpected response'}
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return {code: 1000, message: 'Network error'}
        });
}


export async function addThemeGroup(name, subjectId) {
    let token = localStorage.getItem("token");
    const formData = new FormData()
    formData.append('name', name);
    formData.append('subject_id', subjectId);

    const endPoint = `expert/themeGroup?token=${token}`;
    return await Repository.post(`${baseUrl}/${endPoint}`, formData)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return {code: 1001, message: 'Unexpected response'}
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return {code: 1000, message: 'Network error'}
        });
}

export async function saveThemeGroup(id, name, status) {
    let token = localStorage.getItem("token");
    const formData = new FormData()
    formData.append('name', name);
    formData.append('status', status);

    const endPoint = `expert/themeGroup/${id}?token=${token}`;
    return await Repository.post(`${baseUrl}/${endPoint}`, formData)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return {code: 1001, message: 'Unexpected response'}
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return {code: 1000, message: 'Network error'}
        });
}

export async function deleteThemeGroup(id) {
    let token = localStorage.getItem("token");
    const endPoint = `expert/themeGroup/${id}/delete?token=${token}`;
    return await Repository.post(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return {code: 1001, message: 'Unexpected response'}
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return {code: 1000, message: 'Network error'}
        });
}


