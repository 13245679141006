<template>

  <div class="container">

    <div class="row">
      <div class="col-6">
        <h2>Articles</h2>
      </div>
      <div class="col-6 text-end">
        <button @click.prevent="addNewForIsOpen = false" class="btn btn-outline-danger" v-if="addNewForIsOpen">close
        </button>
        <button @click.prevent="addNewForIsOpen = true" class="btn btn-outline-dark" v-else>add new</button>
      </div>
    </div>

    <div class="card my-4 bg-secondary" v-if="addNewForIsOpen">
      <div class="card-header text-white lead fw-bold">
        New article
      </div>
      <div class="card-body">
        <form @submit.prevent="createArticle">
          <div class="row">
            <div class="col-md-6">
              <div class="form-floating mb-3">
                <select class="form-control" id="articleLanguage" required
                        v-model="newArticle.languageId">
                  <option v-for="l in languages" :key="l.id" :value="l.id">{{ l.name }}</option>
                </select>
                <label for="articleLanguage">Language</label>
              </div>
            </div>
            <div class="col-md-6">

              <div class="form-floating mb-3">
                <input type="number" class="form-control" id="articleListOrder" required
                       v-model.number="newArticle.listOrder">
                <label for="articleListOrder">List Order</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-9">
              <div class="form-floating mb-3">
                <input type="text" class="form-control" id="articleTitle" required
                       v-model="newArticle.title">
                <label for="articleTitle">Title</label>
              </div>
            </div>
            <div class="col-md-3">
              <button type="submit" class="btn btn-lg btn-success w-100">Create</button>
            </div>
          </div>
        </form>

      </div>
    </div>

    <table class="table table-bordered table-striped">

      <thead>
      <tr>
        <th>Order</th>
        <th>Language</th>
        <th>Title</th>
        <th>Status</th>
      </tr>
      </thead>
      <tbody>
      <tr v-if="articles.length<1">
        <td colspan="4">
          <div class="alert bg-warning m-4">No content here</div>
        </td>
      </tr>

      <tr v-for="a in articles" :key="a.id">
        <td>{{ a.listOrder }}</td>
        <td>{{ a.language.name }}</td>
        <td>
          <router-link class="list-group-item" :to="{name: 'adminArticle', params:{id: a.id}}">
            {{ a.title }}
          </router-link>
        </td>
        <td>
          <span class="badge bg-danger" v-if="a.status<1">inactive</span>
          <span class="badge bg-success" v-else>active</span>
          </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>


import {createArticle, getArticleList, getLanguageList} from "@/repository/AdminDataRepository";
import {customAlert} from "@/utils/utils";

export default {
  name: "ArticleList",
  data() {
    return {
      articles: [],
      languages: [],
      addNewForIsOpen: false,
      newArticle: {
        title: '',
        languageId: 0,
        listOrder: 0
      }
    }
  },

  methods: {

    loadList() {

      getArticleList().then(d => {
        this.articles = d;
      })

    },

    createArticle() {
      createArticle(this.newArticle.title, this.newArticle.languageId, this.newArticle.listOrder).then(res => {

        if (res.code === 200) {
          customAlert("success", "Created", 'success');
          this.$router.push({name: 'adminArticle', params: {id: res.id}});
        } else {
          customAlert(`Error ${res.code}`, res.message, 'error');
        }

      })
    }

  },
  computed: {},
  mounted() {
    this.loadList();

    getLanguageList().then(l => {
      this.languages = l;
    })
  }
}
</script>
