<template>
  <div class="my-2 alert alert-info" v-if="row === null">
    loading...
  </div>
  <div class="position-relative content-row" style="min-height: 150px; padding-left: 50px;" v-else>
    <div class="border border-secondary d-flex justify-content-center position-relative"
         :class="`${row.status>0?'border-secondary':'border-danger border-3'}`"
         style="border-style: dashed !important;" :style="fullRowStyle">
      <div :class="{'w-100': row.width==='full', 'w-75': row.width === 'container' }" :style="contextBoxStyle">


        <draggable
            :class="`row  justify-content-${row.align} `"
            style="border: 1px dashed #cccccc; border-top: none; border-bottom: none;"
            v-model="cells"
            :group="`cells_${row.id}`"
            handle=".handle"
            :disabled="cells.length<2"
            @start="dragging=true"
            @end="dragEnd"
            item-key="id">
          <template #item="{ element }">
            <div :class="`col-${element.width} position-relative`"
                 style="border: 1px dashed #cccccc; border-top: none; border-bottom: none; ">
              <ContentCell :col="element" :move="cells.length>1"/>
            </div>
          </template>
        </draggable>

      </div>
      <div style="position: absolute; right: 0; top: 0; padding: 5px; background: white; ">
        <button type="button" class="btn btn-sm btn-success" title="add new column"
                @click.prevent="addCell">
          <font-awesome-icon icon="fa-solid fa-fw fa-diagram-successor"/>
        </button>
      </div>
    </div>

    <div style="position: absolute; left: 0; top: 0; z-index: 2200">
      <div class="card bg-secondary mb-2" v-if="editing" style="z-index: 2200">
        <div class="card-body">
          <form @submit.prevent="saveRow">
            <div class="form-floating mb-3">
              <select class="form-control form-control-sm" :id="`rowWidth${j}`" v-model="row.width" required>
                <option value="container">box</option>
                <option value="full">full width</option>
              </select>
              <label :for="`rowWidth${j}`">Width</label>
            </div>
            <div class="form-floating mb-3">
              <select class="form-control form-control-sm" :id="`rowWidth${j}`" v-model="row.align" required>
                <option value="start">left</option>
                <option value="end">right</option>
                <option value="center">center</option>
                <option value="between">full</option>
              </select>
              <label :for="`rowWidth${j}`">Align</label>
            </div>

            <div class="row">
              <div class="col-md-6">

                <div class="form-floating mb-3">
                  <input type="number" class="form-control" id="articleListOrder" v-model="row.listOrder">
                  <label for="articleListOrder">List order</label>
                </div>

              </div>
              <div class="col-md-6">
                <div class="form-floating mb-3">
                  <select class="form-control form-control-sm" v-model="row.status">
                    <option value="1">active</option>
                    <option value="0">inactive</option>
                  </select>
                  <label>Status</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-floating mb-3">
                  <select class="form-control form-control-sm" :id="`rowWidth${j}`"
                          v-model="row.configuration.background" required>
                    <option value="none">none</option>
                    <option value="color">color</option>
                    <option value="image">image</option>
                  </select>
                  <label :for="`rowBackground${j}`">Background</label>
                </div>
              </div>
              <div class="col-md-6" v-if="row.configuration.background!=='none'">
                <div class="form-floating mb-3">
                  <select class="form-control form-control-sm" :id="`backgroundTarget${j}`"
                          v-model="row.configuration.backgroundTarget" required>
                    <option value="full">full</option>
                    <option value="content">content box</option>
                  </select>
                  <label :for="`backgroundTarget${j}`">Coverage</label>
                </div>
              </div>
            </div>

            <div class="form-floating mb-3" v-if="row.configuration.background === 'color'">
              <input type="color" class="form-control" id="backgroundColor"
                     v-model="row.configuration.backgroundColor">
              <label for="backgroundColor">Color</label>
            </div>


            <div class="row" v-if="row.configuration.background === 'image'">
              <div class="col-md-6">

                <file-upload :file-path="row.configuration.backgroundImage"
                             @changed="row.configuration.backgroundImage = $event" class="mb-3"/>

              </div>
              <div class="col-md-6">
                <div class="form-floating mb-3">
                  <select class="form-control form-control-sm" v-model="row.configuration.backgroundSize">
                    <option value="cover">cover</option>
                    <option value="contain">contain</option>
                    <option value="parallax">parallax</option>
                    <option value="tail">tail</option>
                  </select>
                  <label>Size</label>
                </div>
              </div>
            </div>

            <div class="mt-4">

              <button type="submit" class="btn btn-sm btn-primary me-2">Save</button>
              <button type="button" class="btn btn-sm btn-warning" @click.prevent="editing = false">
                Cancel
              </button>

            </div>
          </form>


        </div>
      </div>

      <div class="text-white small" v-else style="z-index: 2100">
        <button type="button" class="btn btn-sm btn-warning mb-1" @click.prevent="editing = true">
          <font-awesome-icon icon="fa-solid fa-fw fa-cog"/>
        </button>
        <br>
        <button type="button" class="btn btn-sm btn-danger mb-1" @click.prevent="deleteRow">
          <font-awesome-icon icon="fa-solid fa-fw fa-trash"/>
        </button>
        <br>
        <button type="button" class="btn btn-sm btn-primary" title="add new row after this"
                @click.prevent="$emit('add-row-after')">
          <font-awesome-icon icon="fa-solid fa-fw fa-diagram-next"/>
        </button>
      </div>


    </div>

  </div>
</template>
<script>
import {customAlert} from "@/utils/utils";
import {deleteRow, getRow, saveRow, updateCellOrder,} from "@/repository/AdminDataRepository";
import ContentCell from "@/views/Admin/ContentCell";
import draggable from 'vuedraggable'
import FileUpload from "@/components/FileUpload";
import {mediaBase} from "@/repository/repository";

export default {
  name: 'ContentRow',
  components: {FileUpload, ContentCell, draggable},
  props: {
    rowId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      row: null,
      cells: [],
      editing: false,
      addCellView: true,
      newCell: {
        type: '',
        width: 6,
      },
      dragging: false
    }
  },

  computed: {

    fullRowStyle() {
      let style = {};
      if (this.row.configuration.backgroundTarget === 'full') {
        style = this.buildBackgroundStyle()
      }
      return style;
    },

    contextBoxStyle() {
      let style = {};
      if (this.row.configuration.backgroundTarget === 'content') {
        style = this.buildBackgroundStyle()
      }
      return style;
    }

  },
  watch: {
    editing() {
      this.loadItem(false);
    }
  },
  methods: {

    buildBackgroundStyle() {
      let style = {};
      if (this.row.configuration.background === 'image') {
        style['background-image'] = `url('${mediaBase}/${this.row.configuration.backgroundImage}')`;
        style['background-position'] = 'center';
        style['background-repeat'] = 'no-repeat';
        if (this.row.configuration.backgroundSize === 'cover') {
          style['background-size'] = 'cover';
        } else if (this.row.configuration.backgroundSize === 'contain') {
          style['background-size'] = 'contain';
        } else if (this.row.configuration.backgroundSize === 'parallax') {
          style['background-size'] = 'cover';
          style['background-attachment'] = 'fixed';
        }
      } else if (this.row.configuration.background === 'color') {
        style['background'] = `${this.row.configuration.backgroundColor}`;
      }

      return style;
    },
    loadItem(updateCells) {
      getRow(this.rowId).then(d => {
        if (d.code === 200) {
          this.row = d.item;
          this.row.configuration = JSON.parse(d.item.config);
          if (updateCells) {
            this.cells = d.cells;
            if (d.cells.length < 1) {
              this.addCell();
            }
          }
        } else {
          customAlert('Error ' + d.code, d.message, 'error');
        }
      })
    },


    saveRow() {
      saveRow(this.row.id, this.row.width, this.row.align, this.row.listOrder, this.row.status, JSON.stringify(this.row.configuration)).then(res => {
        if (res.code === 200) {
          customAlert("success", "Saved", 'success');
          this.editing = false;
        } else {
          customAlert(`Error ${res.code}`, res.message, 'error');
        }

      })
    },

    addCell() {

      this.cells.push(
          {
            id: 0,
            row: this.row,
            width: 6,
            listOrder: this.cells.length + 1,
            status: 0,
            contentText: "{}"
          }
      );

    },

    deleteRow() {
      if (confirm('are you sure?')) {
        deleteRow(this.row.id).then(res => {
          if (res.code === 200) {
            this.$emit('deleted');
          } else {
            customAlert(`Error ${res.code}`, res.message, 'error');
          }

        })

      }
    },

    dragEnd(ev) {
      if (ev.oldIndex !== ev.newIndex) {

        updateCellOrder(ev.item.__draggable_context.element.id,  ev.newIndex).then(res => {
          if (res.code === 200) {
            customAlert("success", "Saved", 'success');
          } else {
            customAlert(`Error ${res.code}`, res.message, 'error');
          }

        })
      }
    }
  },

  mounted() {
    this.loadItem(true);
  },
  emits: ['deleted']
}
</script>
<style lang="scss" scoped>

.content-row {
  font-family: sans-serif !important;
}

</style>