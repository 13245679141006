export function customAlert(title, decription, type, next) {

    alert(`${title}: ${decription}; ${type}`);

    if (next) {
        next();
    }

}

export function customAsk(title, decription, type, yes, no) {

    if (confirm(`${title}: ${decription}; ${type}`)) {
        if (yes) {
            yes();
        }
    } else {
        if (no) {
            no();
        }
    }

}