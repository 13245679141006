<template>

  <div class="container ">
    <div class="h-100 d-flex align-items-center ">
      <div>
        <h1 class="text-center">LooongRead</h1>

        <div class="card mt-5">
          <div class="card-body">


            <form @submit.prevent="loginUser">
              <div class="form-group">
                <label for="login" class="form-label">Username</label>
                <div>
                  <input type="email" id="login" class="form-control" v-model="login">
                </div>
              </div>
              <div class="form-group">
                <label for="password" class="form-label">Password</label>
                <div>
                  <input type="password" id="password" class="form-control" v-model="password">
                </div>
              </div>
              <div class="form-group">
                <div class="mt-3">
                  <button class="btn btn-success">Login</button>
                </div>
              </div>
            </form>

          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import {customAlert} from "@/utils/utils";
import {loginUser} from "@/repository/AdminDataRepository";

export default {
  name: 'LoginView',
  components: {},
  data() {
    return {
      login: '',
      password: ''
    }
  },
  methods: {

    loginUser() {

      loginUser(this.login, this.password).then(data => {

        if (data.length < 1) {
          alert("something went wrong");
        } else {
          if (data.code === 200) {
            localStorage.setItem("token", data.token);
            location.reload();
          } else {
            customAlert("Error " + data.code + "!", data.message + ":" + data.error, 'error');
          }
        }

      })

    }

  }
}
</script>

<style scoped>
.container {
  max-width: 400px !important;
}

</style>