import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import { createI18n } from 'vue-i18n'

let messages = {
    en: require("@/locales/en.json"),
    ru: require("@/locales/ru.json"),
    sv: require("@/locales/sv.json")
};
const i18n = createI18n({
    locale: 'ru', // set locale
    fallbackLocale: 'en', // set fallback locale
    messages: messages
})

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import {
    faArrowsUpDownLeftRight, faBars,
    faCog,
    faDiagramNext,
    faDiagramSuccessor,
    faEdit, faLanguage, faTimes,
    faTrash,
    faCirclePlay, faPlay, faPause
} from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faEdit);
library.add(faTrash);
library.add(faArrowsUpDownLeftRight);
library.add(faCog);
library.add(faDiagramNext);
library.add(faDiagramSuccessor);
library.add(faBars);
library.add(faTimes);
library.add(faLanguage);
library.add(faCirclePlay);
library.add(faPlay);
library.add(faPause);


import VueInnerImageZoom from 'vue-inner-image-zoom'
import VideoBackground from 'vue-responsive-video-background-player'

createApp(App)
    .use(store)
    .use(router)
    .use(i18n)
    .component('video-background', VideoBackground )
    .component('font-awesome-icon', FontAwesomeIcon)
    .component('inner-image-zoom', VueInnerImageZoom)
    .mount("#app");
