<template>
  <div class="article-banner"
       :style="{'background-image': `url('${mediaBase}/${article.photo}')`} ">
    <a>{{ article.title }}</a>
  </div>
</template>
<script>
import {mediaBase} from "@/repository/repository";

export default {
  name: 'ArticleBanner',
  props: {
    article: {}
  },
  data(){
    return {
      mediaBase: mediaBase
    }
  }
}
</script>
<style lang="scss" scoped>
.article-banner {
  padding: 15px;
  background: no-repeat #000000;
  background-size: cover;
  border-bottom: 1px solid #222222;
  height: 200px;
  display: flex;
  justify-content: left;
  align-items: flex-end;
  position: relative;
  cursor: pointer;
  filter: grayscale(1);

  &:before {
    content: '';
    display: block;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  a {
    color: #ffffff;
    font-size: 20px;
    font-weight: bold;
    z-index: 1;
    max-width: 50%;

    &:hover {
      color: white;
      text-decoration: none;
    }

    @media screen and (max-width: 700px) {
      max-width: 100%;
    }
  }

  &:hover {
    filter: grayscale(0);
    padding: 25px;
  }

}
</style>