<template>

  <div style="zoom: 0.75">
    <ContentBlockItem :col="cell" :key="cell.contentText" :is-admin="true"/>
  </div>

  <div class=" card shadow bg-secondary my-3 editing-view" style="min-width: 400px;">

    <div class="card-body">
      <form @submit.prevent="updateContent">
        <div class="row">
          <div class="col-md-6">

            <div class="form-floating mb-3">
              <select class="form-control form-control-sm" v-model="cell.type" required>
                <option value="header">header</option>
                <option value="text">text</option>
                <option value="photo">photo</option>
                <option value="youtube">Youtube video</option>
                <option value="iframe">Iframe</option>
                <option value="code">Code</option>
                <option value="autoplay_audio">Audioplay audio</option>
              </select>
              <label>Type</label>
            </div>
          </div>
          <div class="col-md-6">

            <div class="form-floating mb-3">
              <input type="number" class="form-control" min="1" max="12" required
                     v-model.number.lazy="cell.width" @change="$emit('size', cell.width )"
              >
              <label>Width</label>
            </div>
          </div>
        </div>


        <div v-if="cellData.type==='text'">

          <div class="bg-white rounded-1 mb-3">
            <label class="m-2">Text</label>
            <QuillEditor theme="snow" :content="blockData.text" contentType="html"
                         @update:content="blockData.text =  $event" :options="editorOptions"/>
          </div>

        </div>
        <div v-if="cellData.type==='header'">

          <div class="form-floating mb-3">
            <textarea class="form-control" v-model="blockData.text" required style="height: 100px;"></textarea>
            <label>Text</label>
          </div>
        </div>
        <div v-if="cellData.type==='youtube'">

          <div class="form-floating mb-3">
            <input type="text" class="form-control" v-model="blockData.video" required>
            <label>Video link</label>
          </div>

          <div class="form-floating mb-3">
            <input type="number" class="form-control" v-model="blockData.start" required>
            <label>Start at second</label>
          </div>

          <div class="form-floating mb-3">
            <select class="form-control form-control-sm" v-model="blockData.ratio" required>
              <option value="1x1">1x1</option>
              <option value="4x3">4x3</option>
              <option value="16x9">16x9</option>
              <option value="21x9">21x9</option>
            </select>
            <label>Ratio</label>
          </div>


          <label>Poster</label>
          <FileUpload :file-path="blockData.poster" @changed="blockData.poster = $event" class="mb-3"/>
        </div>
        <div v-if="cellData.type==='iframe'">

          <div class="form-floating mb-3">
            <input type="text" class="form-control" v-model="blockData.url" required>
            <label>Video link</label>
          </div>

          <div class="form-floating mb-3">
            <select class="form-control form-control-sm" v-model="blockData.ratio" required>
              <option value="1x1">1x1</option>
              <option value="4x3">4x3</option>
              <option value="16x9">16x9</option>
              <option value="21x9">21x9</option>
            </select>
            <label>Ratio</label>
          </div>
        </div>

        <div v-if="cellData.type==='code'">

          <div class="form-floating mb-3">
            <textarea style="height: 150px;" class="form-control" v-model="blockData.code" required></textarea>
            <label>Code</label>
          </div>
        </div>
        <div v-if="cellData.type==='photo'">

          <FileUpload :file-path="blockData.path" @changed="blockData.path = $event" class="mb-3"/>

          <div class="row">
            <div class="col-md-6">
              <div class="form-floating mb-3">
                <select class="form-control form-control-sm" v-model="blockData.ratio" required>
                  <option value="1x1">1x1</option>
                  <option value="4x3">4x3</option>
                  <option value="16x9">16x9</option>
                  <option value="21x9">21x9</option>
                  <option value="1x2">1x2</option>
                  <option value="3x4">3x4</option>
                  <option value="9x16">9x16</option>
                  <option value="free">free</option>
                </select>
                <label>Ratio</label>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-floating mb-3">
                <select class="form-control form-control-sm" v-model="blockData.zoom" required>
                  <option value="no">no</option>
                  <option value="yes">yes</option>
                </select>
                <label>Zoomable</label>
              </div>
            </div>
          </div>


          <div class="form-floating mb-3">
            <input type="text" class="form-control" v-model="blockData.alt">
            <label>Caption</label>
          </div>
        </div>
        <div v-if="cellData.type==='autoplay_audio'">

          <FileUpload :file-path="blockData.path" @changed="blockData.path = $event" class="mb-3"/>

        </div>

        <div class="mt-4">

          <button type="submit" class="btn btn-sm btn-primary me-2">Save</button>
          <button type="button" class="btn btn-sm btn-warning" @click.prevent="closeEditingPanel">Cancel</button>

        </div>

      </form>
    </div>
  </div>

</template>
<script>
import {saveCell, upload} from "@/repository/AdminDataRepository";
import {customAlert} from "@/utils/utils";
import ContentBlockItem from "@/views/ContentBlockItem";
import FileUpload from "@/components/FileUpload";


import {QuillEditor, Quill} from '@vueup/vue-quill';
import ImageUploader from 'quill-image-uploader';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

Quill.register("modules/imageUploader", ImageUploader);

export default {
  name: 'EditBlockItem',
  components: {FileUpload, ContentBlockItem, QuillEditor},
  props: {
    cellData: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    }
  },

  data() {
    return {
      cell: null,
      blockData: {},
      editorOptions: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{header: 1}, {header: 2}],
            [{list: 'ordered'}, {list: 'bullet'}],
            [{script: 'sub'}, {script: 'super'}],
            [{header: [1, 2, 3, 4, 5, 6, false]}],
            [{color: []}, {background: []}],
            [{align: []}],
            ['clean'],
            ['blockquote','link', 'image', 'video']
          ]
          , imageUploader: {
            upload: (file) => {
              return new Promise((resolve, reject) => {
                upload(file, event => {
                  console.log(Math.round((100 * event.loaded) / event.total));
                }).then(response => {
                  console.log(response);
                  if (response.data.code === 200) {


                    console.log(reject);
                    setTimeout(() => {
                      resolve(
                          `${this.mediaBase}${response.data.path}`
                      );
                    }, 500);
                  }
                })
                    .catch((e) => {
                      console.log(e);
                    });

              });


            },
          },
        },
      }
    }
  },
  watch: {
    blockData: {
      deep: true,
      handler(newVal) {
        this.cell.contentText = JSON.stringify(newVal);
      }
    }
  },
  created() {
    this.cell = this.cellData;
    this.blockData = JSON.parse(this.cellData.contentText);
  },
  methods: {
    updateContent() {

      saveCell(this.cell.row.id, this.cell.id, this.cell.width, this.cell.listOrder, this.cell.status, this.cell.type, JSON.stringify(this.blockData)).then(res => {
        if (res.code === 200) {
          customAlert("success", "Saved", 'success');
          this.$emit('saved', res.id);
          this.closeEditingPanel();
        } else {
          customAlert(`Error ${res.code}`, res.message, 'error');
        }

      })

    },
    closeEditingPanel() {
      this.$emit('closeEdit');
    }
  },
  emits: ['saved', 'closeEdit', 'size']
}
</script>

<style scoped>

* {
  font-family: sans-serif !important;
}
</style>
