import Repository, {baseUrl} from './repository';


export async function getLanguageList() {
    const endPoint = `public/languages`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data && response.data.code === 200) {
                return response.data.items;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}


export async function getArticleList(languageCode) {
    const endPoint = `public/articles?language=${languageCode}`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data && response.data.code === 200) {
                return response.data.items;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}

export async function getArticle(id) {
    const endPoint = `public/article/${id}`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return {code: 1001, message: 'Unexpected response'}
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return {code: 1000, message: 'Network error'}
        });
}

