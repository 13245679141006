<template>
  <div  :style="fullRowStyle">
    <div class="py-4" :class="{'container': row.width==='container'}">
      <div :class="`row justify-content-${row.align}`" :style="contentBoxStyle">
        <div v-for=" col in  row.cols" :key="col.id" :class="`col-md-${col.width} `">
          <ContentBlockItem :col="col"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ContentBlockItem from "@/views/ContentBlockItem"
import {mediaBase} from "@/repository/repository";

export default {
  name: 'ContentBlockRow',
  components: {ContentBlockItem},
  props: {
    row: {
      type: Object,
      default() {
        return {};
      }
    },
  },
  data() {
    return {
      configuration: {}
    }
  },


  computed: {

    fullRowStyle() {
      let style = {};
      if (this.configuration.backgroundTarget === 'full') {
        style = this.buildBackgroundStyle()
      }
      return style;
    },

    contentBoxStyle() {
      let style = {};
      if (this.configuration.backgroundTarget === 'content') {
        style = this.buildBackgroundStyle()
      }
      return style;
    }

  },
  methods: {


    buildBackgroundStyle() {
      let style = {};
      if (this.configuration.background === 'image') {
        style['background-image'] = `url('${mediaBase}/${this.configuration.backgroundImage}')`;
        style['background-position'] = 'center';
        style['background-repeat'] = 'no-repeat';
        if (this.configuration.backgroundSize === 'cover') {
          style['background-size'] = 'cover';
        } else if (this.configuration.backgroundSize === 'contain') {
          style['background-size'] = 'contain';
        } else if (this.configuration.backgroundSize === 'parallax') {
          style['background-size'] = 'cover';
          style['background-attachment'] = 'fixed';
        }
      } else if (this.configuration.background === 'color') {
        style['background'] = `${this.configuration.backgroundColor}`;
      }

      return style;
    },
  },
  created() {
    this.configuration = JSON.parse(this.row.config);
  }
}
</script>
<style lang="scss" scoped>

* {

}

</style>