<template>
  <div class=" info-block">
    <EditBlockItem :cellData="cellData"
                   v-if="editing"
                   @close-edit="editing=false"
                   @saved=" cellData.id = $event;  loadItem()"
                   @size="$emit('size',$event)"
    />
    <div v-else style="min-height: 50px;" class="pt-5">
      <div class="cell-actions bg-secondary">
        <a href="#" class="btn btn-dark btn-sm me-2 handle" v-if="move" >
          <font-awesome-icon icon="fa-solid fa-arrows-up-down-left-right" />
        </a>
        <a href="#" class="btn btn-light btn-sm me-2" @click.prevent="editing=true">
          <font-awesome-icon icon="fa-solid fa-edit" />
        </a>
        <a href="#" class="btn btn-danger btn-sm me-2" @click.prevent="deleteBlock">
          <font-awesome-icon icon="fa-solid fa-trash" />
        </a>
      </div>
      <div style="zoom: 0.75" >
        <ContentBlockItem :col="col" :key="col" :is-admin="true" />
      </div>
    </div>
  </div>
</template>
<script>
import ContentBlockItem from "@/views/ContentBlockItem"
import EditBlockItem from "@/views/Admin/EditContentBlockItem"
import {deleteCell, getCell} from "@/repository/AdminDataRepository";
import {customAlert} from "@/utils/utils";

export default {
  name: 'ContentCell',
  components: {ContentBlockItem, EditBlockItem},
  props: {
    col: {
      type: Object,
      default() {
        return {};
      }
    },
    move: {
      type: Boolean,
      default(){
        return true;
      }
    }
  },
  data() {
    return {
      cellData: this.col,
      editing: false,
    }
  },
  watch: {
    editing(newVal) {
      if (newVal === false && this.col.id === 0) {
        this.$emit('deleted');
      } else if (this.cellData.id !== 0) {
        this.loadItem();
      }
    }
  },

  methods: {

    loadItem() {
      getCell(this.cellData.id).then(d => {
        if (d.code === 200) {
          this.cellData = d.item;
          this.$emit('updated', d.item);
        } else {
          customAlert('Error ' + d.code, d.message, 'error');
        }
      })
    },

    deleteBlock() {
      if (confirm('are you sure?')) {
        deleteCell(this.cellData.id).then(res => {
          if (res.code === 200) {
            customAlert("success", "Deleted", 'success');
            this.$emit('deleted');
          } else {
            customAlert(`Error ${res.code}`, res.message, 'error');
          }

        })

      }
    }
  },

  created() {
    if (this.col.id === 0) {
      this.editing = true;
    }
  }
}
</script>
<style lang="scss" scoped>


.info-block {

  font-family: sans-serif !important;
  position: relative;

  .cell-actions {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    display: none;
    padding: 5px;
    z-index: 2000;
  }

  &:hover {
    .cell-actions {
      display: block;
    }
  }

}

</style>