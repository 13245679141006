<template>

  <div>

    <input type="hidden" class="form-control" :value="filePath">


    <div class="mb-2">
      <label class="d-inline-block me-2">
        <a class="btn btn-sm btn-primary">Select file</a>
        <input class="form-control" type="file" ref="file" @change="upload" style="display: none"/>
      </label>

      <button type="button" v-if="photoPath" class="btn btn-sm btn-warning"
              @click.prevent="this.photoPath=''">remove</button>
    </div>

    <div v-if="currentFile" class="progress">
      <div
          class="progress-bar progress-bar-info progress-bar-striped"
          role="progressbar"
          :aria-valuenow="progress"
          aria-valuemin="0"
          aria-valuemax="100"
          :style="{ width: progress + '%' }"
      >
        {{ progress }}%
      </div>
    </div>
    <div v-if="message" class="text-danger">{{ message }}</div>
    <div v-if="photoPath">
      <img alt="photo" :src="`${mediaBase}${photoPath}`" class="img-thumbnail img-fluid"
           style="max-height: 150px;" v-if="isPhoto"/>

      <audio controls v-else-if="isAudio">
        <source :src="`${mediaBase}${photoPath}`"  type="audio/mpeg">
      </audio>
    </div>

  </div>

</template>

<script>

import {mediaBase} from "@/repository/repository";
import {upload} from "@/repository/AdminDataRepository";

export default {
  name: "FileUpload",
  props: ['filePath'],
  emits: ['changed'],
  data() {
    return {
      mediaBase: mediaBase,
      photoPath: this.filePath,
      currentFile: undefined,
      progress: 0,
      message: "",
    };
  },
  computed: {
    fileExtension() {
      if (this.photoPath) {
        return this.photoPath.split('.').pop();
      }
      return '';
    },
    isPhoto() {
      if (this.photoPath) {
        return ['png', 'gif', 'jpg', 'jpeg'].indexOf(this.fileExtension) > -1;
      }
      return false;
    },
    isAudio() {
      if (this.photoPath) {
        return ['mp3', 'wav'].indexOf(this.fileExtension) > -1;
      }
      return false;
    }
  },
  methods: {
    upload() {
      this.progress = 0;

      this.currentFile = this.$refs.file.files[0];
      upload(this.currentFile, event => {
        this.progress = Math.round((100 * event.loaded) / event.total);
      })
          .then(response => {
            if (response.data.code === 200) {
              this.photoPath = response.data.path;
            } else {
              this.message = response.data.error;
            }
            this.currentFile = undefined;
          })
          .catch((e) => {
            console.log(e);
            this.progress = 0;
            this.message = "Could not upload the file! " + e;
            this.currentFile = undefined;
          });
    }
  }, watch: {
    photoPath(newVal) {
      this.$emit('changed', newVal);
    }
  }
}
</script>

<style scoped>

</style>