<template>
  <nav class="navbar navbar-expand-lg  navbar-dark bg-dark">
    <div class="container">
      <router-link class="navbar-brand" to="/admin">
        LooongRead
      </router-link>

      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText"
              aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarText">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link :to="{name: 'adminArticles'}" class="nav-link">Articles</router-link>
          </li>

        </ul>
        <span class="navbar-text" @click.prevent="logoutUser"> {{ user.name }}   <span
            class="badge bg-primary">{{ user.email }}</span> </span>
      </div>


    </div>
  </nav>
</template>

<script>
export default {
  name: "AdminNavbar",
  props: ["user"],
  methods: {
    logoutUser() {
      if (confirm("Logout?")) {
        localStorage.removeItem("token");
        location.reload();
      }
    },
  }
}
</script>

<style scoped>

</style>